<template>
  <div class="left-side"></div>
  <div class="right-side"></div>
  <div class="container">
    <div class="content">
      <router-view />
    </div>
    <div class="navigation-wrapper">
      <nav>
        <div class="links">
          <router-link to="/">Home</router-link>
          <router-link to="/projects">Projects</router-link>
          <router-link to="/about">About</router-link>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>

</script>

<style>
body, html {
  padding: 0;
  margin: 0;
  height: 100%; 
  overflow: hidden; /* Prevent scrolling on the body directly */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100vh; 
  width: 100vw; 
  color: white;
}

.left-side {
  background-color: #3a5a40;
  flex: 0 0 65%; 
  height: 100%; 
}

.right-side {
  background-color: #588157;
  flex: 0 0 35%; 
  height: 100%; 
}

@media (max-width: 992px) {
  #app {
    flex-direction: column; 
  }

  .left-side, .right-side {
    width: 100%; 
    height: auto; 
  }
}

.container {
  width: 100%;
  height: 100%;
  position: absolute; 
  display: flex;
  justify-content: space-between;

  @media (max-width: 992px) {
    flex-direction: column-reverse; 
  }

  .navigation-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
      left: 0;
      right: auto; 
    }
  }

  .content {
    --padding-size: 8vw;
    width: calc(100% - var(--padding-size) * 2); 
    height: calc(100% - var(--padding-size) * 2); 
    padding: var(--padding-size);
    overflow: auto;
  }
}

nav {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 100%;
  text-align: left;

  .links {
    display: flex;
    flex-direction: column;
    gap: 26px;

    @media (max-width: 992px) {
      flex-direction: row;
    }
  }
}

nav a {
  font-weight: bold;
  text-decoration: none;
  color: #a3b18a;
}

nav a.router-link-exact-active {
  color: #dad7cd;
}

</style>
