<template>
  <div class="container">
    <div class="name">
      <h2>{{ name }}</h2>
    </div>
    <div class="title">
      <h1>Software Engineer.</h1>
      <h3>I enjoy coding applications that are not only technical but also aesthetically pleasing.</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  position: absolute;
  left: 0;
  top: 0;

  @media (max-width: 992px) {
    padding-top: 5vh;
  }
}

h1,
h2 {
  margin: 0;
}

.title {
  text-align: left;
  max-width: 500px;
}

h1 {
  font-size: 4em;

  @media (max-width: 992px) {
    font-size: 3em;
  }
}

h3 {
  font-weight: 400;
  font-size: 16px;
  word-spacing: 1px;
}
</style>
